import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>{data.strapiHomepage.hero.title}</h1>
          <form class="uk-form-stacked" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <fieldset class="uk-fieldset">

              <legend class="uk-legend">Contact Us</legend>
              
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div class="uk-margin">
                <label class="uk-form-label" for="form-email">Email</label>
                <div class="uk-form-controls uk-inline">
                  <span class="uk-form-icon uk-form-icon" uk-icon="icon: mail"></span>
                  <input class="uk-input uk-form-width-large" id="form-email" type="email" name="email" />
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="form-name">Name</label>
                <div class="uk-form-controls uk-inline">
                  <span class="uk-form-icon uk-form-icon" uk-icon="icon: user"></span>
                  <input class="uk-input uk-form-width-large" id="form-name" type="text" name="name" />
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="form-message">Message</label>
                <div class="uk-form-controls">
                  <textarea class="uk-textarea uk-form-width-large" id="form-message" type="textarea" name="message" />
                </div>
              </div>
              <div class="uk-margin">
                <button type="submit">Send</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
    }
  }
`;

export default IndexPage;